import React from 'react'
import PropTypes from 'prop-types'
import Helmet from "react-helmet";
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const CareersPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2 className="title is-size-3">{title}</h2>
            <PageContent className="content" content={content} />
          </div>
        </div>
      </div>
    </section>
  );
}

CareersPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CareersPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.CareersPageData.edges[0]

  return (
    <Layout locale={locale}>
      <Helmet>
        <title>{data.frontmatter.title}</title>
        <meta
          name="description"
          content={`${data.frontmatter.description}`}
        />
      </Helmet>
      <CareersPageTemplate
        contentComponent={HTMLContent}
        title={data.frontmatter.title}
        content={data.html}
      />
    </Layout>
  );
}

CareersPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};
export default CareersPage

export const careersPageQuery = graphql`
query careersPageQuery($locale: String) {
  CareersPageData: allMarkdownRemark(
    filter: {frontmatter: { templateKey: {eq: "careers-page"}, locale: { eq: $locale }}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        html
        frontmatter {
          title
          locale
        }
      }
    }
  }
}
`


